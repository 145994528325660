import { AppContextWrapper } from '../util/context';
import UTMContext from '../util/utm-context';
import { useRouteChange } from '../util/hooks';
import { GTMPageView } from '../util/googleTagManager';
import { setUpLocalStorage } from '../util/set-local-storage';

import '../styles/index.scss';
import { useEffect, useState } from 'react';
/* If in development mode, inject axe accessibility tools. */
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const ReactDOM = require('react-dom');
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 1000, {
        runOnly: ['best-practice', 'wcag2a', 'wcag2aa']
    });
}

function App({ Component, pageProps, err }) {
    /* Trigger a page view on in-app routing */
    useRouteChange(GTMPageView);

    /* Set initial state for utmStorage */
    const [utmStorageSet, setUtmStorage] = useState(false);

    useEffect(() => {
        /* hello! this side effect will only be called once, when the component first mounts */
        let timer = 0;
        let twoSecondScript = false;
        let thirtySecondScript = false;
        let increment = () => {
            timer = (timer % 360) + 1;
            if (timer >= 2 && !twoSecondScript) {
                twoSecondScript = true;
                let script1 = document.createElement('script');
                script1.src =
                    'https://pixel.mathtag.com/event/js?mt_id=1557003&mt_adid=247959&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=';
                script1.async = true;
                script1.language = 'JavaScript1.1';
                document.getElementsByTagName('head')[0].appendChild(script1);
            }

            if (timer >= 2 && !thirtySecondScript) {
                thirtySecondScript = true;
                let script2 = document.createElement('script');
                script2.src =
                    'https://pixel.mathtag.com/event/js?mt_id=1557004&mt_adid=247959&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=';
                script2.async = true;
                script2.language = 'JavaScript1.1';
                document.getElementsByTagName('head')[0].appendChild(script2);
            }
        };

       

        setInterval(increment, 1000);

        /**
        * Capture UTMs, referrer, etc. into localstorage
        * This only needs to run once on the first app render
        */
        setUpLocalStorage();
        setUtmStorage(true);

        return () => {
            /* this will be called when the component unmounts, also only once.  you could use this to dispose/clean up any resources you allocated in the outer body */
        };
    }, []);

    return (

          <UTMContext.Provider value={{ utmStorageSet, setUtmStorage }}>
            <AppContextWrapper>
                <Component {...pageProps} err={err} />
            </AppContextWrapper>
          </UTMContext.Provider>

    );
}

export default App;
